<template>
  <vue-final-modal class="modal-categories">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('categories')" />
      </div>
    </div>

    <div class="vfm__body">
      <template v-if="isLoggedIn">
        <transition name="fade" mode="out-in">
          <card-category
            v-if="hasRecentlyGames"
            key="recently"
            type="filled"
            :categoryData="recentlyCategory"
            accent
            @click="closeModal('categories')"
          />
        </transition>

        <transition name="fade" mode="out-in">
          <card-category
            v-if="favoriteGames.length"
            key="favorites"
            type="filled"
            :categoryData="favoritesCategory"
            accent
            @click="closeModal('categories')"
          />
        </transition>
      </template>

      <card-category
        v-for="category in props.categories"
        :key="category.id"
        type="filled"
        :categoryData="category"
        @click="closeModal('categories')"
      />
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent, ICollection } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['categories']>;
    defaultLocaleData: Maybe<CIModalsContent['categories']>;
    categories: ICollection[];
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const gamesStore = useGamesStore();
  const { hasRecentlyGames, favoriteGames } = storeToRefs(gamesStore);
  const { recentlyCategory, favoritesCategory } = gamesStore;
</script>

<style src="~/assets/styles/components/modal/categories.scss" lang="scss" />
